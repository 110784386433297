<!-- 成员编辑弹窗 -->
<template>
  <a-modal
    :width="800"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改成员' : '新建成员'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col
          :md="12"
          :sm="24"
          :xs="24"
          v-for="(item, index) in customFieldList"
          :key="index"
        >
          <a-form-item :label="item.label + ':'" :name="item.fieldId">
            <a-input
              v-if="item.type === '单行文本'"
              allow-clear
              :maxlength="20"
              :placeholder="item.placeholder"
              v-model:value="form[item.fieldId]"
            />
            <a-input
              v-if="item.type === '手机号'"
              allow-clear
              :maxlength="20"
              :placeholder="item.placeholder"
              v-model:value="form[item.fieldId]"
            />
            <a-select
              v-if="item.type === '下拉框'"
              v-model:value="form[item.fieldId]"
              placeholder="请选择"
              allow-clear
            >
              <a-select-option
                v-for="(value, index) in strToArr(item.listData)"
                :key="index"
                :value="value"
                >{{ value }}</a-select-option
              >
            </a-select>
            <a-radio-group
              v-if="item.type === '单项选择'"
              v-model:value="form[item.fieldId]"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="(value, index) in strToArr(item.listData)"
                  :key="index"
                >
                  <a-radio :value="value">{{ value }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
            <a-checkbox-group
              v-if="item.type === '多项选择'"
              v-model:value="form[item.fieldId]"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="(value, index) in strToArr(item.listData)"
                  :key="index"
                >
                  <a-checkbox :value="value">{{ value }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <a-input-number
              v-if="item.type === '数字'"
              v-model:value="form[item.fieldId]"
              placeholder="请输入"
              allow-clear
            ></a-input-number>
            <a-textarea
              v-if="item.type === '多行文本'"
              allow-clear
              v-model:value="form[item.fieldId]"
              placeholder="请输入"
            ></a-textarea>
            <a-date-picker
              v-if="item.type === '日期'"
              v-model:value="form[item.fieldId]"
            />
            <a-upload
              v-if="['单张图片', '多张图片', '健康码'].includes(item.type)"
              name="picList"
              list-type="picture-card"
              v-model:file-list="fileList[`${item.fieldId}List`]"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, item.fieldId)"
              @remove="removeFile"
              :maxCount="item.maxCount || 1"
            >
              <div v-if="fileList[`${item.fieldId}List`].length < (item.maxCount || 1)">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              :bodyStyle="{ paddingTop: '50px' }"
              @cancel="previewVisible = false"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import validate from '@/utils/validate'
import * as corpUserApi from '@/api/corp/corpUser.js'
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'UserEdit',
  emits: ['done', 'update:visible'],
  components: {
    PlusOutlined
  },
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    corpId: Number
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      previewVisible: false,
      previewImage: '',
      fileList: {},
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {
        username: [
          {
            required: true,
            type: 'string',
            trigger: 'blur',
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  return reject(new Error('请输入手机号'))
                }
                return resolve()
              })
            }
          }
        ],
        nickname: [
          {
            required: true,
            message: '请输入成员名',
            type: 'string',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '请选择性别',
            type: 'string',
            trigger: 'blur'
          }
        ],
        roleIds: [
          {
            required: true,
            message: '请选择角色',
            type: 'array',
            trigger: 'blur'
          }
        ],
        email: [
          {
            pattern: validate.email,
            message: '邮箱格式不正确',
            type: 'string',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            pattern: /^[\S]{5,18}$/,
            message: '密码必须为5-18位非空白字符',
            type: 'string',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            pattern: validate.phone,
            message: '手机号格式不正确',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],
      // 自定义字段
      customFieldList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        console.log('form', this.form)
      } else {
        this.form = {}
      }
      if (this.data && this.data.id) {
        this.isUpdate = true
      } else {
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      this.queryFormField()
    }
  },
  methods: {
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, fieldId) {
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      console.log(formData, file)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            const obj = {
              uid: uid,
              name: 'image.png',
              status: 'done',
              url: res.data.location
            }
            const index = this.fileList[`${fieldId}List`].findIndex((item) => {
              return item.uid === uid
            })
            this.fileList[`${fieldId}List`].splice(index, 1, obj)
            console.log('fileList', this.fileList[`${fieldId}List`])
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    strToArr(str) {
      if (str) {
        const arr = str.split(',')
        return arr
      } else {
        return []
      }
    },
    arrToStr(arr) {
      if (arr.length > 0) {
        console.log('arr', arr)
        const str = arr.join(',')
        return str
      } else {
        return ''
      }
    },
    queryFormField() {
      // const that = this
      corpUserApi
        .fieldList({
          formId: 'corp-' + this.form.corpId,
          enable: true,
          sort: 'sortNumber',
          order: 'asc'
        })
        .then((res) => {
          if (res.code === 0) {
            this.customFieldList = res.data
            this.customFieldList = this.customFieldList.map((item) => {
              if (item.type === '多项选择' || item.type === '标签') {
                this.form[item.fieldId] = this.strToArr(this.form[item.fieldId])
                return item
              }
              if (
                item.type === '单张图片' ||
                item.type === '多张图片' ||
                item.type === '健康码'
              ) {
                this.fileList[`${item.fieldId}List`] = this.strToArr(
                  this.form[item.fieldId]
                ).map((url, index) => {
                  return {
                    status: 'done',
                    name: 'image.png',
                    message: '',
                    url: url,
                    uid: index
                  }
                })
                return item
              }
              return item
            })
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    filterForm() {
      const params = {}
      this.customFieldList.forEach((item) => {
        if (item.type === '多项选择' || item.type === '标签') {
          params[item.fieldId] = this.arrToStr(this.form[item.fieldId])
        }
        if (
          item.type === '单张图片' ||
          item.type === '多张图片' ||
          item.type === '健康码'
        ) {
          params[item.fieldId] = this.arrToStr(
            this.fileList[`${item.fieldId}List`].map((item) => {
              return item.url
            })
          )
        }
      })
      return params
    },
    /* 保存编辑 */
    save() {
      const params = this.filterForm()
      this.$refs.form
        .validate()
        .then(() => {
          this.form = Object.assign({}, this.form, params)
          console.log('form', this.form)
          this.loading = true
          corpUserApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>
